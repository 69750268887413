<template>
  <footer  id="contact-us">
      <v-container fluid>
          <div class="footer-content"  v-scroll-reveal>
              <v-row>
                  <v-col lg6 md12 xs12>
                      <div class="contact-form">
                          <material-card width="800px">
                              <v-form
                          ref="formContact"
                          v-model="valid"
                          lazy-validation>
                            <v-text-field v-model="nama" 
                            :rules="nameRules" label="Nama Lengkap" required />
                            <v-text-field v-model="email" :rules="emailRules"
                            label="Alamat email" required/>
                            <v-textarea v-model="pesan" auto-grow
                            rows="1" :rules="pesanRules" placeholder="Isi pesan" />
                          </v-form>
                          <v-card-actions>
                              <a class="main-button" v-on:click="kirimPesan">Konfirm</a>
                          </v-card-actions>
                          </material-card>
                      </div>
                  </v-col>
              </v-row>
          </div>
      </v-container>
  </footer>
</template>

<script>
import firebase from 'firebase'
export default {
name:'contact',
data(){
    return{
        valid:true,
        nama:null,
        email:null,
        pesan:null,
        nameRules:[
            v => !!v || 'nama harus di isi',
        ],
        emailRules:[
            v=>!!v || 'Alamat email harus di isi',
            v=>/.+@.+\..+/.test(v) || 'Email harus valid'
        ],
        pesanRules:[
            v=>!!v || 'pesan tidak boleh kosong'
        ]
    }

},
methods:{
    kirimPesan(){
        const a=firebase.firestore().collection('kontak');
        a.add({
            nama: this.nama,
            email:this.email,
            pesan: this.pesan
        }).then((ref)=>{
            console.log('added new kontak '+ref)
            this.$refs.formContact.reset()
        })
    }
}
}
</script>

<style>

</style>