<template>
    <div fluid data-app class="overflow-hidden" >
      <core-header app />
      <core-drawer v-if="isMobile"/>
          <v-main id="home" overflow-y-auto>
            <welcome />
            <fitur />
            <promo />
            <testimoni />
            <contact />
          </v-main>
    </div>
</template>
<script>
import Welcome from './components/Welcome'
import Fitur from './components/Fitur'
import Contact from './components/Contact'
import Promo from './components/Promo.vue'
import Testimoni from './components/Testimoni.vue'
export default {
    components:{
        Welcome,
        Fitur,
        Contact,
        Promo,
        Testimoni
    },
    data(){
      return{
        isMobile:false,
      }
    },
    beforeDestroy(){
      if(typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize,{passive:true})
    },
    mounted(){
      this.onResize()
      window.addEventListener('resize', this.onResize,{passive:true})
    },
    methods:{
      onResize(){
        this.isMobile=window.innerWidth < 600
      },
    }
}
</script>
<style scoped>
.right-image-decor {
  background-image: url(../assets/images/right-bg-decor.png);
  background-repeat: no-repeat;
  background-position: right center;
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 110%;
  margin-top: -200px;
}
.left-image-decor {
  background-image: url(../assets/images/left-bg-decor.png);
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 110%;
  margin-top: -200px;
  left: 0px;
}
</style>
