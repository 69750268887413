<template>
    <v-navigation-drawer
    id="app-drawer"
    v-model="drawer"
    app dark
    floating
    persitent mobile-breakpoint="991" width="260">
        <v-list>
            <v-list-item v-for="(link,i) in links"
        :key="i"
        :to="link.to"
        class="v-list-item">
            <v-list-item-action>
                <v-icon>{{link.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-title v-text="link.text"/>
        </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
    data(){
        return{
            links:[
                {
                    to:'/#welcome',
                    icon:'fa fa-home',
                    text:'Home'
                },{
                    to:'/#fitur',
                    icon:'fa fa-building',
                    text:'Fitur'
                },{
                    to:'/#testimonials',
                    icon:'fa fa-comment',
                    text:'Testimoni'
                },{
                    to:'/karir',
                    icon:'fa fa-briefcase',
                    text:'Karir'
                },{
                    to:'/faq',
                    icon:'fa fa-question',
                    text:'FAQ'
                },{
                    to:'/blog',
                    icon:'fa fa-newspaper',
                    text:'Blog'
                },{
                    to:'/tentang-kami',
                    icon:'fa fa-address-card',
                    text:'Tentang Kami'
                },{
                    to:'/#contact-us',
                    icon: 'fa fa-address-book',
                    text:'Hubungi kami'
                }
            ]
        }
    },
    computed:{
        drawer:{
            get(){
                return this.$store.state.app.drawer
            },
            set(val){
                this.setDrawer(val)
            }
        }
    },
    methods:{
        ...mapMutations('app',['setDrawer','toggleDrawer'])
    }
}
</script>