import Vue from 'vue'
import Axios from 'axios'

Axios.interceptors.request.use((config)=>{
    const token = this.$store.state.token;
    if(token){
        config.headers.Authorization=`Bearer ${token}`
    }
    return config;
}, (err=>{
    return Promise.reject(err)
}))
Vue.prototype.$http=Axios;