// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_dC-S1owJb8mKbkso7pFJMTrgwASLGl0",
    authDomain: "jasavast-com.firebaseapp.com",
    databaseURL: "https://jasavast-com.firebaseio.com",
    projectId: "jasavast-com",
    storageBucket: "jasavast-com.appspot.com",
    messagingSenderId: "188290417322",
    appId: "1:188290417322:web:13789e35cebcb282c5c6d3",
    measurementId: "G-DGNTXYZFBH"
  };
export default firebaseConfig;