import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
// import DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document'
// const options = {
//     editor: {
//         classic: DocumentEditor
//     },
//     fontSize: {
//         options: [
//             9, 11, 12, 13, 'default',
//             17, 19, 21
//         ]
//     },
//     toolbar: [
//         'heading', 'bulletedList', 'numberedList', 'fontSize', 'undo', 'redo'
//     ],
//     name: 'ckeditor'
// }
Vue.use(CKEditor)