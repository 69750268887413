<template>
    <v-app-bar class="main-nav nav" 
    mobile-breakpoint="1024"
      absolute 
      color="#fff"
      elevate-on-scroll
      scroll-target="#home">
      <template v-if="isMobile">
        <v-app-bar-nav-icon @click.stop="onClickBtn"/>
      </template>
      <v-toolbar-title class="logo">Jasavast</v-toolbar-title>
      <template v-if="!isMobile">
        <v-spacer/>
        <v-btn text @click="goTo('#welcome')">
          Home
        </v-btn>
        <v-btn text @click="goTo('#fitur')">
          Fitur Kami
        </v-btn>
        <v-btn text @click="goTo('#testimonials')">
          Testimoni
        </v-btn>
        <div class="text-center">
          <v-menu 
          open-on-hover
          rounded
          bottom
          offset-y>
          <template v-slot:activator="{on,attrs}">
            <v-btn text v-bind="attrs" v-on="on">
              Company Profiles
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <a href="/tentang-kami">
                Tentang Kami
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="/karir">Karir</a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="/faq">FAQ's</a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <a href="/blog">Blog</a>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </div>
        <v-btn text @click="goTo('#contact-us')">
          Hubungi Kami
        </v-btn>
      <v-spacer/>
      </template>
      </v-app-bar>
</template>
<script>
import {mapMutations} from 'vuex'
export default {
  data(){
    return{
      isMobile:false,
    }
  },
    methods:{
      ...mapMutations('app',['setDrawer','toggleDrawer']),
      goTo(e){
        this.$router.push(e);
      },
      onResize(){
        this.isMobile=window.innerWidth < 600
      },
      onClickBtn(){
        this.setDrawer(!this.$store.state.app.drawer)
      }
    },
    beforeDestroy(){
      if(typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize,{passive:true})
    },
    mounted(){
      this.onResize()
      window.addEventListener('resize', this.onResize,{passive:true})
    }
}
</script>
<style scoped>
@media(max-width: 991px){
  .logo{
    text-align: center;
    float: none!important;
  }
}
</style>