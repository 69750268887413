import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './components'
import './style/lava.css'
import './plugins'
import 'firebase/firestore'
import firebase from 'firebase/app'
import konfig from './config/firebaseConfig'
Vue.config.productionTip = false
firebase.initializeApp(konfig)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')