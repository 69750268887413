<template>
  <section class="section" id="promotion">
      <v-container>
          <v-row>
              <div class="left-image col-lg-5 col-d-12 col-sm-12 mobile-bottom-fix-big"
              v-scroll-reveal.reset>
                <img src="@/assets/images/worker.png" 
                width="300px" height="300px" class="rounded img-fluid d-block mx-auto">
              </div>
          </v-row>
      </v-container>
  </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#promotion {
  padding: 100px 0px;
}

#promotion .left-image {
  align-self: center;
}

#promotion .right-text ul {
  padding: 0;
  margin: 0;
}

#promotion .right-text ul li {
  display: inline-block;
  margin-bottom: 60px;
}

#promotion .right-text ul li:last-child {
  margin-bottom: 0px;
}

#promotion .right-text img {
  display: inline-block;
  float: left;
  margin-right: 30px;
}

#promotion .right-text h4 {
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 15px;
  margin-top: 3px;
}
</style>