<template>
    <section class="section" id="testimonials">
        <v-container>
            <v-row>
                <v-col lg-8>
                    <div class="center-heading">
                        <h2>Testimoni <em>Tentang kami</em></h2>
                    </div>
                </v-col>
                <v-col lg-4>
                    <v-carousel
                        cycle
                        height="400"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          v-for="(slide, i) in slides"
                          :key="i"
                        >
                          <v-sheet
                            :color="colors[i]"
                            height="100%"
                          >
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                            >
                              <div class="display-3">
                                <material-card>
                                  {{}}
                                </material-card>
                              </div>
                            </v-row>
                          </v-sheet>
                        </v-carousel-item>
                      </v-carousel>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
export default {
    data(){
        return{
            testimonies:[
              {
                nama:"test"
              }
            ],
            colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        }
    }
}
</script>
<style scoped>

.center-heading {
    margin-bottom: 70px;
}

.center-heading h2 {
    margin-top: 0px;
    text-align: center;
    font-size: 42px;
    font-weight: 700;
    color: #1e1e1e;
    margin-bottom: 25px;
}

.center-heading h2 em {
    font-style: normal;
    color: #f4813f;
}

.center-heading p {
    font-size: 15px;
    color: #888;
    text-align: center;
}
</style>