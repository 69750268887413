import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/error/NotFound.vue'
Vue.use(VueRouter)

const paths = [{
    path: '/',
    name: 'Home',
    component: Home
}, {
    path: '/edit',
    name: 'Edit',
    component: (resolve) =>
        import ('@/views/cms/Editor').then(resolve)
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition){
            return savedPosition
        }
        if (to.hash) {
            return window.scrollTo({
                top: document.querySelector(to.hash).offsetTop,
                behavior: 'smooth'
            })
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
    routes: [
        ...paths, {
            path: '*',
            redirect: '/404'
        }, {
            path: '/404',
            component: NotFound
        }, {
            path: '/register',
            component: (resolve) =>
                import ('@/views/auth/Register').then(resolve)
        },
    ]
})

export default router