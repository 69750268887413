<template>
      <section class="welcome-area" id="welcome">
        <div class="header-text">
            <v-container>
                <v-row>
                    <div class="left-text col-lg-6 col-md-12 col-sm-12 col-xs-12"
                        data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                        <h1>Untuk <em>Komunitas</em> Indonesia</h1>
                        <p>Jasavast merupakan generator aplikasi sederhana untuk komunitas anda.
                          <br />Dalam rangka memberikan pelayanan terbaik kami, kami menyertakan modul-modul yang mungkin 
                          <br />berguna untuk memperkuat komunitas serta berkoordinasi dengan tim anda 
                          <br /> <em style="font-style:italic;">Dari Kami untuk Komunitas Anda</em></p> 
                        <a href="/register" class="main-button-slider">Daftar Sekarang</a>
                    </div>
                </v-row>
            </v-container>
        </div>
    </section>
</template>
<script>
export default {
  name:'welcome'
}
</script>
<style scoped>

.welcome-area{
    width: 100%;
    height: 100vh;
    min-height: 793px;
    background-image: url('../../assets/images/hand1.png');
    background-repeat: no-repeat;
    background-position: right top;
}
.welcome-area .header-text .left-text {
  position: relative;
  align-self: center;
  margin-top: 35%;
  transform: perspective(1px) translateY(-30%) !important;
}

.welcome-area .header-text h1 {
  font-weight: 900;
  font-size: 52px;
  line-height: 80px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #191a20;
}

.welcome-area .header-text h1 em {
  font-style: normal;
  color: #fba70b;
}

.welcome-area .header-text p {
  font-weight: 300;
  font-size: 17px;
  color: #7a7a7a;
  line-height: 30px;
  letter-spacing: 0.25px;
  margin-bottom: 40px;
  position: relative;
}
.overlay-img{
  background: rgba(#ff6600,.7);
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

@media (max-width: 1420px) {
  .welcome-area .header-text {
    width: 70%;
    margin-left: 5%;
  }
}

@media (max-width: 1320px) {
  .welcome-area .header-text .left-text {
    margin-top: 50%;
  }
  .welcome-area .header-text {
    width: 50%;
    margin-left: 5%;
  }
}

@media (max-width: 1140px) {
  .welcome-area {
    height: auto;
  }
  .welcome-area .header-text {       
    width: 92%;
    padding-top: 793px;
  }
  .welcome-area .header-text .left-text {
    margin-top: 5%;
    transform: none !important;
  }
}
@media (max-width: 810px) {
  .welcome-area {
    background-size: contain;
  }

  .welcome-area .header-text {
    padding-top: 100%;
    margin-left: 0;
  }
    
  .container-fluid .col-md-12 {
    margin-right: 0px;
    padding-right: 0px;
  }
}
</style>