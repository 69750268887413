<template>
  <v-app>
      <v-container fill-height grid-list-md text-xs-center>
        <v-layout row wrap align-center>
          <template v-if="!isMobile">
              <v-flex>
            <v-card flat>
                <v-card-text>
                    <div id="empat">
                    <svg width="225px" height="224px" 
                    viewBox="0 0 225 224" version="1.1" 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <image id="Unknown" stroke="none" fill="none" 
                    x="0" y="0" width="225" height="224" 
                    xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADgCAYAAAD17wHfAAAABGdBTUEAA1teXP8meAAAG2BJREFUeAHtXVmMFUUXLmQHQbYA
                    CYTlZ5NNAQmMhIiSUXxgUQMPIgK+mIhEHRNQwYQlgQQIGg1oojGAOJqA0UGMATGyOMEBIaDsKKuAYFCQffX+9V3TQ8+dvn2ru2vr7nOSm9tdXX2q6qs6XVWnTp2qkeHEiAgBQsAYAnc
                    ZS5kSJgQIgSwCJITUEAgBwwiQEBquAEqeECAhpDZACBhGgITQcAVQ8oQACSG1AULAMAIkhIYrgJInBEgIqQ0QAoYRICE0XAGUPCFAQkhtgBAwjAAJoeEKoO
                    QJARJCagOEgGEESAgNVwAlTwiQEFIbIAQMI0BCaLgCKHlCgISQ2gAhYBgBEkLDFUDJEwK1CAJCgBAIjsDly5fZ+fPn2e+//84OHz7MTp8+nf3fuHEja968Ofvggw9Y165
                    dhRjXIPcWQjhRpBQjcO3aNbZz586skG3evJnt3r2b7d+/n505c8YXlXXr1rHi4mLfOHhIQlgQIoqQJgRu377NDh06xLZt25b9ffnll+zo0aOhIfjxxx9Z
                    UVGR7/upFsKKigq2adMmhq8bvnatWrVijzzyCBs0aJDwUMIXXXoYCwSOHz/OysvL2YoVKxjaRKEeLkih0KYg1A0bNsz/GoajaSM+TMh0794dXuby/gYMGJBZuXJl2qB
                    JRXkvXbqUQRuYMmVKpkOHDnnbgF/7CPJsyZIlvrgy36cJfPjiiy8GAr1Xr14ZPqRIIBLpKtK5c+cyq1evzowaNSpQ/QcRtnxxGzRo4At2aoTw1q1
                    bmWHDhoWugBkzZvgCSQ/tQwB1jh7v6aefDl3v+QQraDgf4uYFKDVCOGTIkMgVgSHsiRMn8oJJD+xAYMeOHZmSkpLI9R1U0Pzi+42mUrFOOHPmTIb1m6i0b98+
                    1rZtWyai8YqaFr0fDAGs2/E5PHv//ffZ1q1bg72sIfb169fzp2LHt0tdLjAP4KWX/istLVWXaeIsjABfs7Ou1/Nqbxs2bMhbpkQPRzEO9wJEVtjixYvzAksP1CKARm1CyR
                    K27fgNRxMthFEUMaJgk8JGrbDlcsfIBhpr0fqxJR7mqfkosUKItRldFUCCmK95yQvHmq2ONT1VbebChQt5wUikEKoehnpV1LRp0/KCTA/CI4CeL87C57QVPwQSqR19/vnnedn
                    10ty5c9nNmzfZ/PnzpSUMUzrwhD0j6MaNG9l7twlU7dq1Wb169VjNmjWlpWsDI2izJ06cGMlu04ZyIA/jx4/3zUrihBCVt2rVKt9Cq3q4YMGCLOswggj7xSNHjrAtW7awPXv2ZO0
                    XYblfyI6RW2OwHj16sC5dumSXT7i5HevcuTPr3bt3LAXz4MGD7KWXXmJr165VVU3a+cIe2Zf8usm4PeNrMRluMKttLsiBrZIWFEGwRzx27FhB6GC/CA0f4ufykXUPAwVocA8cOFAwP
                    6YjYAoR1KRQFk6q+RRqD4maE6LBqQbUzR82gVDKQJhgmyhCqBAIHt5181J9DY0i8BHNp0hZZMXRqURTjXMuf+BeiBIjhGhcuQCouO/fv39m4cKFgXsXqKhHjhypJY+
                    Fyo0ex4beEWtnJkcuhXCS8VxEc56YOeHs2bM5ZuqIg8nGjh0bep8h5m7dunVTl0FBztyYObtfEsocU3Tx4kX2xhtvMN4zm8qCtnSfeOKJwmkV6irj8BxfdV5S6T/MqaAix1x
                    TFuHrryKv+XiiDOi50RNjV4FpwnpfvrwmLRxTDhFKxHBUtvkShmuwSVRFqudAwAMfD5vmf1C8yK4n24UWHz8Rir0QQlhkVYaoZlMEWL846JFkL0Bjz5xtgudgAGN3WXUUJz6i8+
                    7YC6GMryuED19qnTRv3rzIDROCDI2n7ryL4oR82aKM0i28WK4SpVgLYdRecNy4cca0hFHmhlB7Y8e4DXO8fA0NvbLuhm9Teii/KMVaCMPukhg4cGBWUSEKkop4WKwP2miQb799aS
                    ryGYYnPo5x3OkQtD784gf5QMZWCKHt8wMh3zObNuPmy6NXeBw9v0EYk2oF41VHTpjI2qD74xZbIQw6F0RjsElbiEoQUc5w41/r8u1uQCLX8MuDXSZOI036v6hCxsEulkIYdF0Q8ycbq
                    ZDSAksZSSIIY9J7RkwZglIshVC0IqF4wdzLVvIz3vbbiW1reUTzhWEqjAiS2CMGUcg4eMVOCPE1Fam8OMyhsJjrVRY00jRQEq1nrl69GrjqYnc+4YcffsjbbX7i8yzGdyqw0
                    aNH549kyZNmzZpVywkXQNazZ89q4UkMQB3xtUTG5/eJKB7si0PZ5AYWW4MvwIaT11beH4apQVTDBouSTTp3rTDMUMZ0GWSlr9qUz6/dyHpWaN9gPqxiNRz1M3+C5Uj
                    cyD20hhY07RR22UmWEEXhgzluWIqVEOZbAI7DAna+CnIUFLYtn+TLr+pwzKkcTKIIhe53o2jgYyOEuUM3B2SVSgzYPsrcxuTVgFF5QRd3vfgkKQxTClENuNMOTP63a9
                    cuUjuJjRDmnqyDgttquBxUIOI0jw1atijx/ZZwTApdbtpRp0KxOKn3/PnzrGnTprzs/xE/HSnrlaxRo0ZOEP0nFAEc5jNr1iyrS8fXov1P4i2Q+1gsUZSVlVUWg/t4IQGsRCP5
                    FxBCbvJmbUGRN7cf2DAZjUVP+PDDD2ePNuOKGbZ9+3ZWp06dMGWld2KMwOTJk630SYM1aT41ioSs9T0hnMHCoS/3ysV++OEHEsBI1R3flxctWsT41jWrCsCVR5EFEAWyXgi
                    doej+/ftZkyZNrKoEyoxeBNAW8DG2haZOnSolK9YLISblMOUiAZRS37FmApOwNWvWWFEGWb0gCmP1nBBnMvzxxx+suLjYCuApE3YgsHTpUvbcc88ZzQy3dmJt2rSRkgerhRCnEoUyiJU
                    CDTGxGQFHWWcij3z9UurpW1YLoQmAKc14IIBTrNq3b28ks9zEUOr0yPo5oRGUKVHrEcCyAN+PqT2fcN0vWz9BPaH2aqQEZSGAw1Nr1dJ3nArOE7l8+bKs7FfyoZ6wEgq6iBsCOJ2Y
                    b2/Tlu1vvvlGSVrUEyqBlZjqQgBHiMOu+MqVK0qTxJHXy5YtU5IG9YRKYCWmuhCACSPmaarpnXfeUZYECaEyaImxLgSEzgCMkBkMeWUrY9zZISF0o0HXsUQAAgILFhUEJ1Q4HFYl0Zx
                    QJbrEWxsCMPLHAr5sgje4li1bymZbhR8JYRU45N9ApV27dm3a/SEf2iocVSxXcNcjWkwmaThapSrl3+zatYvBMwCRWgSwXMFdoEhLBJt1ddkskxBKqzZvRl988QX1gt7QSA8dPny
                    4FJ78PAk2Z84cKbxEmNBwVASlkHGwhlW3bl0W1QdJyORT95ose1LZtqGFKoJ6wkIIRXj+6aefRnibXg2KQPPmzYO+Ui0+d62pdDmiWoI8gITQCxUJYegFX3
                    /99Synf//9VwJHYlEIAThciuICgx9DwIqKigolI/05CaF0SP9jiF4Q6m0QNHdEehDo3LlzqISwI0PWnDJoBkgIgyImEB/aUPfO76NHjwq8RVFkIBDG8xlOU3r11VdlJB+KB
                    wlhKNj8X3rzzTerRIArBCI9CLRt2zZQQrC0gW9Tk0RCKBn97777rppBMbR2RHoQCGLdAgGEK0XTREIosQYwDH300UercSwvL68WRgFqEIB1kgiVlJRYIYDIKwmhSI0Jxslnzb9
                    q1SpBDhRNBwKYA7711ls6khJKg4RQCKbCkTCxhxGxF2HDKTyJE5lHAC4qnKUj87n5LwckhBJq4r333mNvv/22L6dt27b5PqeHchC4efNmQUYicQoykRiBhDAimFgPFNnLtmLFiogp0esi
                    CMRRE61VCJO2aA0BfOaZZ0TaBsO8kHZTCEEVKdKff/4Z6X0TL2sTQsyJli9fbqKMStKEK3ZRAXQyQLakDhLq/uO4HKRNCH/55ZesFQlsKuNOWNx1W8SIlmfBggWiUSleSARoOOoD3IYNG7
                    JPbdNM+WS52iMMpydMmBD6+GaYr3399dfV+FKAHARwdklFRYUcZhq5aNtP2Lp160qDZl1uA2TiiGHO448/zvbt2xeJbYcOHdiRI0ci8aCXvRHAfLDQ+YV4fujQoc
                    hHXHvnIFyoluEowHF2FCCbsCqJ0wT6888/zx4+ElUAUXb0huBHJB8BTHkKETZZwxWGTaRFCE+dOlWtzHAhcPHixWrhNgVAmwl3d2PGjJGaLfAjTalUSLPMRMwD7
                    7rrLoafTaQlN3v37q1WZvQIQ4cOZbYqaqDJhHv1zz77rFreZQTk7rSQwTPtPETc1NevX986mLQIIU7c9SJYkXTp0sWqoSkm9j169Ai8/OBVPr8wuG4nJY0fQsGenTx5M
                    jvUL/QWhqKpHI76rd3gGSbLprVaO3fuZPC2/OCDD0ZWvhRqCM7zESNGMDQeougIwDeMCMEFRiqFcPv27QXxQeOfPn26kvPf/BKH0TW0nn379mVfffWVX1Qlz6CksnVIrqTA
                    ipiKGoKoPFMidNEyiunWrVsZnrlAvyVLlmSuX7+uLGd8QTfDh4MZvlwQKF9ByyEaf+TIkcrKmgbGqEtRrPloxzpImOocXb16VRigXCD5vq/M7t27pWQRfFBZ3BtX6Pzk5k/m/
                    bhx46SUM21MVq5cGag+uZdu6yBSvliPOU9Qvx+8cVchzBmxVDBo0CDWvXt3Bmc+2EENVbMzvoc1C1wL4h/qf6zpwYSprKwsazxdhaGlN3DjTval4pUDVyJengz8OPCPnX02zKo/Cz
                    t27Aj0peIACsfnwpjp379/plevXhkunBm+YVP43SDp6IyLnlrlUFx1feviH7QHdOqQn7irK4vC6dTimVNKsOdTRdCs+mleVaWrku/atWuz65PoycO471OZN1t4YxO1yB5Or/wWMmvzekd
                    1mPJ1Qv5VV12GxPGHO4z27dvTOmJOzToG9GEFEOxatGiRw9X8rXIhNF/E+OYA64iTJ0/WvmxjI2JYx23Tpg37+OOPI2Uvqn4iUuJ5XiYhzAOMLcGwrOnUqRODEiKtNH/
                    +/Ow6rnsTQFgsgvglDZtG0PeUCyGs1omiIYDGBy0gXCrmMwGMloKdb+PDgy1wr732mrQMivollZagACPlQtioUSOBbFAUEQTgp4ZrgrND1KQppNzlx9ATVkz4
                    8Mjo/dy877nnHvetHdfCetSQEWGdwktKPwUYcAWFNGOGkNUr9TXufUG5McWxY8ek5lkGM+UWM1jzIiFU+xEaMGBAhp+tl7lw4YKMNqGVBz8VNwMzRaz56mgnMKO0jZRbzECtXK
                    uW8uVIO4YVFuQC6ntsGh48eHClNZEF2aqSBawdY77HF9wjazurMBa44QIoEEtzFB1fhSFDhmj5ynHoKB0XBrCTLC0tzWAIBhteU3Tp0qVsHtDjwVjdVD3BYN9G0tJF9evXL+85DZq/O
                    alKDl4BHM8AsBSBJwP0kD179mQdO3ZUZpEDpRGcWUGTC5cT33//vXQFS5iKvP/++8O8pvwdLUIY9ghj5aVPUQLQMrqF0ik6tK34SEIw7777boZ1NOy5g2VJs2bNnGiV/xhKwqLn
                    7NmzWUN5OOziPV1W4H766SdtG6IrMxTgok+fPgFi64uqRQix+4HITgT4Fi+GXxrIxoV64K58nRCJwNcmESFgGgH09jaSFiHE8IZvObKx/JSnFCGAebCNpEUIUfAwZzfYCBjlKb4IN
                    G7c2MrMaxPC4uJiKwGgTKUDATibttLJE4dfmxB27do1a/eYjiqnUtqGgM3TIW1CiEp54YUXbKsbyk9KELBZQ69VCEePHp2SKqdi2oYAvKrbSlqFEOs03NGOrVhQvhKMQO/eva0tnV
                    YhBApTp061FgzKWDIR4B7srDVmB+LahRALpvCvSUQI6EIANrM2k3YhBBg4852IENCFwEMPPaQrqVDpGBFCLFdEcVsXqqT0UmoRsHk+iEpRvqk3X83DVT0O4SQiBFQiEIejBYz0hAA
                    d1gt8w6lK/Ik3IcCeeuop61Ew1hM6yOCgF2fjqRNG/4SALAS43x1mu8c/Yz2hAzLOFSAiBFQggBOYbBdAlNu4EGJYKnrUsYqKIp7JRWDChAmxKJzx4aiD0tKlS2m7kwMG/UtBgDt1ksJHNRPj
                    PaFTwIkTJ7KSkhLnlv4JgUgILFy4MNL7Ol+2pid0Co0hRNSTdxxe9J9eBLhTYWv3D+bWijU9oZOxZcuWMdj6
                    ERECYRGYNm1abAQQZbSuJ3SAxwlEOACFiBAIikCcekGUzbqe0AG8rKyMDL0dMOhfGIF58+bFqhdEwaztCR3Up0+fzubOnevc0j8hkBcBeBmH9+86derkjWPjA2t7QgesOXPmM
                    H6GgXNL/4RAXgQ++eST2AlgtjA2HpDhlSecXcczTD/CwLMN4KzGuJL1w1H3Zw/nHowYMYJt3brVHUzXKUcAw9CjR4+yevXqxRIJ64ejblTho2bLli0Mk28iQsBBYNO
                    mTbEVQJQhVkLogA4/NbA35ae7OkH0n1IE+AnFDJvE40yxFEIAXlRUxH799VeGhVmidCIAhd3w4cPjX/i4Tmbd+d6xY0eGuzn3nLDzGqLwBGKAE4iTQiwpBUE5cBwzCV3yPzr8r
                    PskNdtMrLSjIuOOy5cvs1mzZrEFCxaIRKc4MUKgQYMGjI96Yj8HzIU8tnPC3II49w0bNmTz589nx44dI7M3B5QE/MNzO5ao4q6E8ayKRPXrHoXhR0FneAXSMDWm80K+BphZt26dR80mJyhRc0
                    K/aoEwcvd3JIwxEkbM8a9fv+5XrYl4lhohdGoLwggTJz4soJ+FGPTq1SsDxcvVq1edKkv8f+IUM55jbo9AzC/g6Q1KHCLzCMyYMYONGTOG4ayStFFqhdCp6IsXL7Lly5ezKVOmsCtXrjjB9K8ZA
                    VhAwQAjjZQ47WjQSoRfykmTJjEsbfCdGqRRDQqgpPiLFy+WxCl+bFLfE3pV2cmTJ7OqcOoZvdBRF8aVMPHcDxgRktT3hF74Ya2RBNALGbVh2A2RRiIh9Kj1n3/+2SOUglQjUF5erjoJK/mTEHpUy
                    /r16z1CKUg1ApiTp5FICD1qvaKiwiOUglQjsH//fnbjxg3VyVjHnxQzOVWCRlC3bt2cULrVhcCJEydYmzZtdCVnRTrUE+ZUAzYKE5lDAEtFaSMSwpwaP3LkSE4I3epE4ObNmzqTsyItEsKcati2
                    bVtOCN3qRODs2bM6k7MiLRLCnGogpUwOIJpvqSfUDLiNyR04cMDGbFGeEowA9YSuysXOCjiRJSIEdCJAQuhC+9q1a647ujSBQIsWLUwkazRNEkIX/KdOnXLd0aUJBODMKW1EQuiq8cOHD7vu6NI
                    EAnE9TyIKViSELvROnz7tuqNLEwg0btzYRLJG0yQhdMF/4cIF1x1d6kagQ4cOsTtlVwZGJIQuFMlaxgWGgctu3boZSNV8kiSErjqAw2Aicwg88MAD5hI3mDIJoQt8EkIXGAYu+/btayBV80mSEL
                    rqgBbqXWAYuLzvvvsMpGo+SdpP6KqDGjVquO7oUjcCt27dYjVr1tSdrPH0qCc0XgWUASDAjyhIpQCi7CSEQIHIOAKDBw82ngdTGahlKuE0p3vmzBnWsmVLdvz4cQZTuUuXLmWP/dqzZw/DMsnff/
                    /NsJsjTXPU4uLi1DYJmhO6ql7HnBAexYYMGeJK1fsSvm7Onz/PYFR+8OBBBt8rEEq8v3HjRu+XYhzKT32Jce6jZZ16wmj4BXq7pKRESADBtE6dOtneEtft2rXDXxWCgEIo9+7dyzZv3sx+++03tnbt
                    2ipx4nKDw2DSTNQTumpfdU+o2pMYDrc5dOgQ27lzJysrK2OrVq1ylc7eSxyB3adPH3szqDhnJIQugFUKIT8TkS1atMiVmp5LzDPRQ3777bdW9pT8PEK2a9cuPWDYmkriT2AMUEBeR5iYKPnxo78C5E
                    RN1HPnzmUP4Bw2bJiSMobBjp/GpKawMeJKPaHr66jqIBhsVLXNnyY0s+3bt3eV3swldq7geLo0E60TumofQyMV9Morr6hgG4knlD1eCp9ITAO+jINZ0y6AgIyE0NVwunbt6rqTd/nYY4/JYyaRU/fu3SVyC85q8uTJwV9K4BskhK5K7dSpk+tO3mX//v3lMZPIqVWrVhK5BWM1bdo04z1xsByri01C6MIWViyyaeTIkQxzTRvJ1FAQxgqzZ8+2ERIjeSIhdMHes2dP152cSxHrGDkpBecCgwDdhCEwlkvSuFsiH9YkhC5kOnbs6LqTczlo0CA5jBLCBUYEJoTfZvhICF2107p1ayZ7ntSvXz9XCnZd/vXXX1oztHLlSqZK+aW1IJITIyF0AYov9MCBA10h0S7By+av/j///BOtgAHeHjVqFBs9enSAN9ITlYQwp66ffPLJnJDwt+PHjw//soY3dbr955YxGkoUzyRICHPqbejQoTkh4W9tVsqgVLqGoxDAtB2BHaTVkNmaB1oYRm7dutXjiXgQtIAwTLZVC3j79m32v//9L7uxWLxU4WJyM85wL6bkLeoJPSr65Zdf9ggNFjRx4kRrBdApCexHVRM2IRP5I0A9oQc+mCvVr1/f44l4EHyYmrbN9Mstdu7XrVvXL0rkZ3y3BluzZk1kPklnQD2hRw3jZKDS0lKPJ2JB8BxmswCKlSJ6rHfffTc6kxRwICHMU8ljx44VdkWRy2LmzJm5Qdbdqz4bHpuYaU1QrNppOOqDE/y4NG3a1CdG9UfQBE6aNKn6A8tCcDS4bMMEdxEdj3LuMLr2RoB6Qm9csqFNmjTJejkT3WcIh0VxEEAUDh8YVbRw4cJKJ1Wq0kgSXxLCArWJ9S04TvJbbMZyxLp161gchqFOcVUNR+FFgPYJOiiL/dNwVAynyliOg14EQIGDeU8clTD4sKg4BWn16tVs+PDhlXjRRWEEyO9oYYyqxMB2JxVbnqokouFGhckaliRIAINXHg1Hg2OWiDfQi8umjz76SDbLVPAjIUxFNasvJNmHhseYhDA8drF+EwoUWQQXHnHRCssqs0w+pJiRiWaMeMlaJ4Qwnzx5kmE5hygcAtQThsMt9m/JEhqcIyGLV+xBDVkAEsKQwMX9NRlbrLBDgkzTorcEEsLoGMaSA4QQLifCEj9bI7Rtbdg0k/oeCWFSa1agXGGOI8OGZxzxVlRUJJACRRFBgIRQBKWExgl6Tjy2d1VUVJCrCsntgbSjkgGNEzu4uCgvL2fr16/PCte+ffuquLsYMGAAu/fee9mzzz7L0nymvOo6JSFUjXCM+MOUzTFnw5wRyw8yFDgxgsBIVkkIjcBOiRICdxCgOeEdLOiKEDCCAAmhEdgpUULgDgIkhHew
                    oCtCwAgCJIRGYKdECYE7CJAQ3sGCrggBIwiQEBqBnRIlBO4g8H832pJ5UpHDXQAAAABJRU5ErkJggg=="></image>
                    <path class="eyes" fill="#6495ED" d="M110.798 67.246c0 6.211 4.353 10.965 9.726 10.965 5.371 0 
                    9.723-4.754 9.723-10.965 0-15.276-19.449-15.276-19.449 0zM55.799 60.246c0 6.211 4.353 10.965 9.726 10.965 5.371 
                    0 9.723-4.754 9.723-10.965 0-15.276-19.449-15.276-19.449 0z" /></svg>
                    </div>
                    <p class="hal">Halaman yang anda tuju belum tersedia</p>
                    <p class="hal"> Kembali ke <a href="/"><em>Home Page</em></a> ?</p>
                </v-card-text>
            </v-card>
          </v-flex>
          </template>
          <template v-else>
              <v-flex>
                  <v-card flat>
                      <div class="error-page">
                  <div>
                      <h1 data-h1="404">404</h1>
                      <p data-p="NOT FOUND">NOT FOUND<br />
                      </p>
                  </div>
              </div>
              <div id="particles-js"/>
                  </v-card>
                    <v-card flat>
                        <a href="/">Back to <em>Home</em></a>
                    </v-card>
              </v-flex>
          </template>
        </v-layout>
      </v-container>
  </v-app>
</template>
<script>
export default {
    data(){
        return{
            isMobile:false,
        }
    },
    methods:{
        onResize(){
        this.isMobile=window.innerWidth < 600
      },
    },
    beforeDestroy(){
      if(typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize,{passive:true})
    },
    mounted(){
      this.onResize()
      window.addEventListener('resize', this.onResize,{passive:true})
    }
}
</script>

<style scoped>
.error-page{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-family: Arial,"Helvetica Neue",Helvetica,sans-serif
}
.error-page h1{
    font-size: 30vh;
    font-weight: bold;
    position: relative;
    margin: -8vh 0 0;
    padding: 0;
}
.error-page h1::after{
    content: attr(data-h1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: transparent;
    background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176, #b98acc, #69a6ce, #9b59b6);
        -webkit-background-clip: text;
        background-clip: none;
        -webkit-text-fill-color: transparent;
    background-size: 400%;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
    animation: animateTextBackground 10s ease-in-out infinite;
}
.error-page h1 + p{
    color: #d6d6d6;
  font-size: 8vh;
  font-weight: bold;
  line-height: 10vh;
  max-width: 600px;
  position: relative;
}
.error-page h1 + p::after{
    content: attr(data-p);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
#empat::before, #empat::after{
    content: "4";
    font-size: 200px;
}
#particles-js {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@keyframes animateTextBackground {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@media (max-width: 767px) {
  .error-page h1 {
    font-size: 32vw;
  }
  .error-page h1 + p {
    font-size: 8vw;
    line-height: 10vw;
    max-width: 70vw;
  }
}
a.back {
  position: fixed;
  right: 40px;
  bottom: 40px;
  background: -webkit-repeating-linear-gradient(-45deg, #71b7e6, #69a6ce, #b98acc, #ee8176);
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding: 15px 30px;
  text-decoration: none;
  transition: 0.25s all ease-in-out;
}
a.back:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}


.hal{
    text-align: center;
    margin-top: 40px;
    margin-left: 25px;
    font-size: 40px;
}
a{
    text-decoration: none;
    color: black;
}
a:hover{
    padding: 20px;
    background: #6488ED;
    color: orange;
    border-radius: 50%;
}
.eyes{
    -webkit-animation: move 2s forwards infinite;
    animation: move 2s forward infinite;
}
@-webkit-keyframes move{
    0% {
        -webkit-transform: translateX(0%);
    }
    20%{
        -webkit-transform: translate(4px, -4px);
    }
    40%{
        -webkit-transform: translate(6px,-4px);
    }
    60%{
        -webkit-transform: translate(12px,0px);
    }
    80%{
        -webkit-transform: translate(6px,6px);
    }
    100%{
        -webkit-transform: none;
    }
}
@keyframes move {
    0% {
      transform: translateX(0%);
  }
  20% {
      transform: translate(4px, -4px);
  }
  40% {
      transform: translate(6px, -4px);
  }
  60% {
      transform: translate(12px, 0px);
  }
  80% {
      transform: translate(6px, 6px);
  }
  100% {
      transform: none;
  }
}
</style>