<template>
    <section id="fitur">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="4" v-for="(item,i) in feature" 
              :key="item.id">
              <material-card text class="features-item xs-12">
                  <div class="features-icon">
                            <h2>{{(i+1).toString().padStart(2,'0')}}</h2>
                            <img :src="item.gambar" height="64" width="64" alt="">
                            <h4>{{item.title}}</h4>
                            <p v-html="item.shortDesc">{{item.shortDesc}}</p>
                            <a :href="item.url" class="main-button">
                                Read More
                            </a>
                        </div>
                </material-card>
            </v-col>
          </v-row>
        </v-container>
    </section>
</template>
<script>
import Firebase from 'firebase'
export default {
    data(){
        return{
            feature:[
            ],
        }
    },
    mounted(){
      this.feature.length=0;
      var data =Firebase.firestore().collection('fitur');
      data.get().then(doc =>{
        doc.forEach(d=>{
          console.log(d.id,'=>',d.data());
          console.log(d.data().gambar)
          this.feature.push({
            id:d.id,
            gambar: d.data().gambar,
            title: d.data().title,
            shortDesc: d.data().shortDesc,
            url: d.data().url
          })
        })
      })
    }
}
</script>
<style scoped>
#fitur {
  padding: 100px 0px;
  position: relative;
  z-index: 9;
}
.mGrid{
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: repeat(4, 1fr) auto auto;
  grid-auto-columns: 40%;
}

@media (max-width: 992px) {
  .features-item {
    margin-bottom: 45px;
  }
}

.features-item {
  border-radius: 5px;
  padding: 60px 30px;
  max-height: 400px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.features-item h2 {
  z-index: 9;
  position: absolute;
  top: 15px;
  left: 45px;
  font-size: 100px;
  font-weight: 600;
  color: #fdefe9;
}

.features-item img {
  z-index: 10;
  position: relative;
  max-width: 100%;
  margin-bottom: 40px;
}

.features-item h4 {
  margin-top: 0px;
  font-size: 22px;
  color: #1e1e1e;
  margin-bottom: 25px;
}

.features-item .main-button {
  margin-top: 35px;
  display: inline-block;
}
</style>